<template>
    <div class="bg-gray-50 border border-gray-200 rounded-lg p-3">
        <table class="w-full">
            <th class="border-b border-gray-300 pb-2 min-w-[250px]">Konto</th>
            <th class="border-b border-gray-300 pb-2">Text</th>
            <th class="border-b border-gray-300 pb-2" width="120" align="right">Debet</th>
            <th class="border-b border-gray-300 pb-2" width="120" align="right">Kredit</th>
            <tr v-for="item in transactions" :key="item.id">
                <td class="border-b py-2">{{ item.account }} {{ getAccountName(item.account) }}</td>
                <td class="border-b py-2">{{ item.text }}</td>
                <td class="border-b py-2" align="right">{{ item.debit | swedishNumberFormat }}</td>
                <td class="border-b py-2" align="right">{{ item.credit | swedishNumberFormat }}</td>
            </tr>
        </table>
        <div v-if="!transactions.length" class="flex flex-col items-center py-10">
            <img src="@/assets/images/no_data.svg" class="w-32" />
            <p class="mt-4">Ingen data</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            accountsData: [],
        };
    },

    props: {
        clientId: {
            type: String,
            default: null,
        },
        transactions: {
            type: Array,
            default: () => [],
        },
    },

    watch: {
        clientId() {
            this.getAccounts();
        },
    },

    methods: {
        async getAccounts() {
            const { data } = await this.axios.get(`booking/accounts/${this.clientId}`);
            this.accountsData = data;
        },

        getAccountName(account) {
            return this.accountsData.find(item => item.number === account)?.name;
        },
    },
};
</script>
